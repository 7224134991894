#work {
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;

}

.workTitle {
    margin: 1rem 0;
    font-size: 3rem;
}

.workDesc {
    font-weight: 300;
    font-size: 1rem;
    max-width: 45rem;

}

.projects {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 65rem;

}

.workImg {
    object-fit: contain;
    height: 10rem;
    width: 15rem;
    margin: 0.5rem;

}
.viewProject{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    
}
.viewProject:hover{
    color: #549db8;
    cursor: pointer;

}

.github {
    object-fit: cover;
    width: 2rem;
}
.project{
    margin: 2rem;
}
p{
    font-size: 0.8rem;
}
svg{
    height: 0.5rem;
}

span{
    font-size: 0.75rem;
    
}

@media screen and (max-width:585px) {
    .workImg {
        height: 48vw;
    }
}