#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;


}

.introImage{
    
    position: absolute;
    top: -4rem;
    right: 0;
    z-index: -1;
    object-fit: cover;
    height: 100vh;
}

.introContent{
    height: 100vh;
    width: 100vh;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.hello{
    font-size: 1.75rem;
    font-weight: 200;
}

.introName{
    color: #549db8;
}

p{
font-size: medium;
    font-weight: 300;
}

.introBtn{
    background-color: white;
    
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    
    border-radius: 3rem;
}

.hireBtn{
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;
    padding-right: 0.5rem;

}

@media screen and (max-width:840px){
   .introImage{
    right: -5vw;
   } 
   .introContent{
    font-size: 10vw;
   }
   .hello{
    font-size: 4.5vw;
   }
}

@media screen and (max-width:550px){
    .introImage{
        right:-10vw
    }
}

