.navbar {
    background: rgb(30, 30, 30);
    height: 5rem;
    width: 100vw;
    margin: 0 auto;
    max-width: 75rem;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;

}

.logo {
    object-fit: cover;
    height: 7rem;
    width: 7rem;
    filter: brightness(1.6);
    
}

.desktopMenuListItem {
    margin: 1rem;
    cursor: pointer;
}

.desktopMenuListItem:hover {
    color: #549db8;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #549db8;

}

.desktopMenuBtn {
    background: white;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.75rem;
    border-radius: 3rem;

}


.desktopMenuBtnImg {
    object-fit: cover;
    width: 1rem;
    height: 1rem;
    margin: 1rem;

}

.active {
    color: #549db8;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #549db8;
}
.mobMenu{
    display: none;
    object-fit: cover;
    height: 3rem;
}
.mobileMenu{
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
    height: fit-content;
    min-width: 15rem ;
    background: rgb(40,40,40);
    border-radius: 1rem;


}

.listItem{
    color: white;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: rgb(30, 30, 30);
    cursor: pointer;

}

.listItem:hover {
    color: #549db8;

}



@media screen and (max-width: 720px){
    .mobMenu{
        display: flex
    }
    .desktopMenuListItem{
        display: none;
    }
    .desktopMenuBtn{
        display: none;
    }
}
