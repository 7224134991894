.footer{
    width: 100vw;
    height: 4rem;
    background: rgb(40, 40, 40);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: small;
    

}

@media screen and (max-width: 720px) {
    .footer {
        height: 3.5rem;
        /* Reduce height for smaller screens */
        font-size: 0.84rem;
        /* Slightly smaller font */
       
        padding: 0 10px;
        /* Add padding to avoid edges being too close */
        text-align: center;
        /* Center text if it overflows */
    }
}