.hero{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 35px;
}
.krish{
    height: 200px;
    width: 200px;
    border-radius: 100px;
    margin-top: 100px;
}

.hero  span{
    text-align: center;
    width: 70%;
    font-size: 50px;
    font-weight: 600;

}


.heroName {
    color: #549db8;
}
.hero p{
    width: 50%;
    text-align: center;
    font-size: 24px;
    line-height: 40px;

}
.introBtn {
    background-color: white;

    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 3rem;
}

.hireBtn {
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;
    padding-right: 0.5rem;

}

.krish {
    animation: updown 8s linear infinite;
}

@keyframes updown {
    0% {
        transform: translateY(-20px);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(-20px);
    }
}