#work{
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
    
}

.workTitle{
    margin: 1rem 0;
    font-size: 3rem;
}

.workDesc{
    font-weight: 300;
    font-size: 1rem;
    max-width: 45rem;

}

.workImgs{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 65rem;

}

.workImg{
    object-fit: cover;
    height: 20rem;
    margin: 0.5rem;

}

@media screen and (max-width:585px){
    .workImg{
        height: 48vw;
    }
}